import React, { useState } from 'react';
import ProfilePicture from '../gif/ProfileMini.png';
import { RouteLocation } from 'enums/routeLocation.enum';
import HeaderButton from './HeaderButton';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

interface HeaderProps {
    actualRoute: RouteLocation;
    setActualRoute: (value: RouteLocation) => void;
}

const Header: React.FC<HeaderProps> = ({actualRoute, setActualRoute}) => {
    const [isMenuCollapsed, setIsMenuCollapsed] = useState(true);
    const routeLocations = Object.values(RouteLocation);
    const actions = [
        { name: 'Email', link: "mailto:suberoerick@gmail.com" },
        { name: 'LinkedIn', link: "https://www.linkedin.com/in/ericksubero/" },
        { name: 'Blog', link: "https://ericksubero.wordpress.com/" },
    ];

    return (
        <div className="min-h-full">
            <nav className="bg-darker">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex h-16 items-center justify-between">
                        <div className="w-full flex items-center justify-between">
                            <div className="hidden md:block">
                                <div className="flex items-baseline space-x-4">
                                    {routeLocations.map((routeLocation) => (
                                        <HeaderButton
                                            key={routeLocation} 
                                            actualRoute={actualRoute}
                                            routeToCompare={routeLocation}
                                            setActualRoute={setActualRoute}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="shrink-0">
                                <button className="px-3 py-2 text-2xl font-medium font-montserrat text-lighter">Erick Subero's Website</button>
                            </div>
                        </div>
                        <div className="-mr-2 flex md:hidden">
                            {/* Mobile menu button */}
                            <button 
                                type="button"
                                className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700
                                        hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                aria-controls="mobile-menu"
                                aria-expanded="false"
                                onClick={()=>setIsMenuCollapsed(!isMenuCollapsed)}
                            >
                                <span className="absolute -inset-0.5"></span>
                                <span className="sr-only">Open main menu</span>
                                
                                <svg className="block size-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                </svg>
                                
                                <svg className="hidden size-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                {!isMenuCollapsed && (
                    <div className="md:hidden transition-all duration-300 ease-in-out" id="mobile-menu">
                        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3 transition-all duration-300 ease-in-out">
                            {routeLocations.map((routeLocation) => (
                                <HeaderButton
                                    key={routeLocation} 
                                    actualRoute={actualRoute}
                                    routeToCompare={routeLocation}
                                    setActualRoute={setActualRoute}
                                    isMobile
                                />
                            ))}
                        </div>
                    </div>
                )}
                
            </nav>
        </div>
    );
}

export default Header;