import React from 'react';

interface InfoCardProps {
    title: string;
    description: string;
}

const InfoCard: React.FC<InfoCardProps> = ({title, description}) => {
    return (
        <div className='flex flex-col gap-2 px-5 py-4'>
            <p className='text-xl font-bold text-darker font-montserrat'>{title}</p>
            <p className='text-base text-dark font-montserrat'>{description}</p>
        </div>
    );
}

export default InfoCard;