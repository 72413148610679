import React from 'react';

interface GameCardProps {
    cardLink: string;
    cardTitle: string;
    cardImage: string;
    cardDescription: string;
}

const GameCard: React.FC<GameCardProps> = ({cardLink, cardTitle, cardImage, cardDescription}) => {

    return (
        <div className="group relative md:py-5 py-0">
            <img 
                src={cardImage}
                alt={cardTitle}
                className="w-full rounded-lg bg-lighter object-cover group-hover:opacity-75 max-sm:h-80 sm:aspect-[2/1] lg:aspect-square" 
            />
            <h3 className="mt-6 text-2xl font-semibold text-darker">
                <a href={cardLink}>
                    <span className="absolute inset-0"></span>
                        {cardTitle}
                </a>
            </h3>
            <p className="text-base text-dark">{cardDescription}</p>
        </div>
    );
}

export default GameCard;