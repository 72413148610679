import React from 'react';

import constructionGif from 'assets/gif/construction.gif';
import saberKnightGif from 'assets/gif/Saber-Knight.gif';
import theHacklistGif from 'assets/gif/The-Hacklist.gif';
import wildSquadGif from 'assets/gif/Wild-Squad.gif';
import heartHealersGif from 'assets/gif/HeartHealers.gif';
import discDoomGif from 'assets/gif/DiscDoom.gif';
import Header from 'components/portfolio/Header';
import { ViewProps } from 'interfaces/ViewProps';
import GameCard from 'components/portfolio/GameCard';

const Games: React.FC<ViewProps> = ({actualRoute, setActualRoute}) => {
    const games = [
        { cardLink:"https://play.google.com/store/apps/details?id=com.PakataGames.PutASmile", cardTitle:"Put A Smile", cardImage:constructionGif, cardDescription:"Developed with Unity",  },
        { cardLink:"http://canodrome.com/", cardTitle:"Canodrome", cardImage:constructionGif, cardDescription:"Developed with Unity",  },
        { cardLink:"https://dracobk201.itch.io/disc-doom", cardTitle:"Disc Doom", cardImage:discDoomGif, cardDescription:"Developed with Unity",  },
        { cardLink:"https://play.google.com/store/apps/details?id=com.izrrdev.wildsquad", cardTitle:"Wild Squad", cardImage:wildSquadGif, cardDescription:"Developed with Unity",  },
        { cardLink:"https://causticgames.itch.io/the-hacklist-prologue", cardTitle:"The Hacklist", cardImage:theHacklistGif, cardDescription:"Developed with Unity",  },
        { cardLink:"https://connect.unity.com/p/sabre-knight-fight-for-the-eye", cardTitle:"Saber Knight", cardImage:saberKnightGif, cardDescription:"Developed with Unity",  },
        { cardLink:"http://2013.globalgamejam.org/2013/heart-healers", cardTitle:"Heart Healers", cardImage:heartHealersGif, cardDescription:"Developed with Gamemaker",  },
    ];

    return (
        <div>
            <Header actualRoute={actualRoute} setActualRoute={setActualRoute} />
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-darker">Games</h1>
                </div>
            </header>
            <main>
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
                        {games.map((game) => (
                            <GameCard
                                cardLink={game.cardLink} 
                                cardTitle={game.cardTitle} 
                                cardImage={game.cardImage} 
                                cardDescription={game.cardDescription} 
                            />
                        ))}
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Games;