import InfoCard from 'components/corporative/InfoCard';
import React from 'react';

const PutASmileTermService: React.FC = () => {
	
    return (
        <div className="flex flex-col gap-2 bg-lighter mx-14 my-6 p-3 rounded-lg shadow-md shadow-dark">
            <p className='text-center text-4xl font-bold text-darker font-montserrat'>Terms of Service</p>
            <dl className="divide-y divide-regular">
                <InfoCard
                    title='Acceptance of Terms'
                    description='By downloading, installing, or using the game Put A Smile (the "Game"), you agree to these Terms of Service (the "Terms"). If you do not agree to these Terms, you must not download, install, or use the Game.'
                />
                <InfoCard
                    title='Grant of License'
                    description='Subject to these Terms, Pakata Games (the "Developer") grants you a non-exclusive, non-transferable, and revocable license to use the Game on a compatible mobile device.'
                />
                
                <div className='flex flex-col gap-2 px-5 py-4'>
                    <p className='text-xl font-bold text-darker font-montserrat'>Restrictions</p>
                    <p className='text-base text-dark font-montserrat'>You may not:</p>
                    <ul className='pl-8 list-disc'>
                        <li className='text-base text-dark font-montserrat'>Sell, rent, lease, or distribute the Game to third parties.</li>
                        <li className='text-base text-dark font-montserrat'>Modify, decompile, or reverse engineer the Game.</li>
                        <li className='text-base text-dark font-montserrat'>Remove or modify any intellectual property notices from the Game.</li>
                        <li className='text-base text-dark font-montserrat'>Use the Game for any illegal or commercial purpose.</li>
                        <li className='text-base text-dark font-montserrat'>Use the Game in a manner that may infringe the rights of third parties.</li>
                    </ul>
                </div>
                <InfoCard
                    title='Intellectual Property'
                    description='The Game and all intellectual property rights related thereto are the property of the Developer.'
                />
                <InfoCard
                    title='Privacy'
                    description='The Developer may collect information about your use of the Game. The Developer will use this information in accordance with its Privacy Policy.'
                />
                <InfoCard
                    title='Disclaimer'
                    description='THE GAME IS PROVIDED "AS IS" AND "AS AVAILABLE". THE DEVELOPER MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE GAME. THE DEVELOPER WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE GAME.'
                />
                <InfoCard
                    title='Indemnification'
                    description="You agree to indemnify and defend the Developer from any claim, damage, loss, or expense (including attorneys' fees) arising out of or related to your use of the Game."
                />
                <InfoCard
                    title='Modifications'
                    description="The Developer may modify these Terms at any time. If the Developer modifies these Terms, it will notify you by a notice in the Game or by email. If you continue to use the Game after the changes to the Terms are posted, you will be deemed to have accepted such changes."
                />
                <InfoCard
                    title='Termination'
                    description="The Developer may terminate your license to use the Game at any time, for any reason, and without notice. If the Developer terminates your license, you must cease using the Game immediately."
                />
                <InfoCard
                    title='General Provisions'
                    description="These Terms are governed by the laws of the State of California, USA. If any provision of these Terms is held to be unenforceable, the remaining provisions will remain in full force and effect. These Terms constitute the entire agreement between you and the Developer with respect to the Game."
                />

                <div className='flex flex-col gap-2 px-5 py-4'>
                    <p className='text-xl font-bold text-darker font-montserrat'>Contact</p>
                    <p className='text-base text-dark font-montserrat'>If you have any questions about these Terms, you may contact the Developer at the following address:</p>
                    <p className='text-base text-dark font-montserrat'>
                        <a href="mailto:suberoerick@gmail.com">suberoerick@gmail.com</a>
                    </p>
                </div>
                <p className='text-right px-5 py-4 text-sm text-darker font-montserrat'>Effective Date: February 5, 2024</p>
            </dl>
        </div>
    );
}

export default PutASmileTermService;