import React, { useState } from 'react';
import { ViewProps } from 'interfaces/ViewProps';
import Header from 'components/portfolio/Header';

const Contact: React.FC<ViewProps> = ({actualRoute, setActualRoute}) => {
    const actions = [
        { icon: "https://img.icons8.com/?size=100&id=12623&format=png&color=000000", name: 'Email', link: "mailto:suberoerick@gmail.com" },
        { icon: "https://img.icons8.com/?size=100&id=8808&format=png&color=000000", name: 'LinkedIn', link: "https://www.linkedin.com/in/ericksubero/" },
        { icon: "https://img.icons8.com/?size=100&id=7875&format=png&color=000000", name: 'Blog', link: "https://ericksubero.wordpress.com/" },
    ];

    return (
        <div>
            <Header actualRoute={actualRoute} setActualRoute={setActualRoute} />
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-darker">Contact Information</h1>
                </div>
            </header>
            <main>
                <div className="flex flex-col lg:flex-row gap-4 mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <h2 className="text-center text-lg/8 font-semibold text-gray-900">If you wish to contact me, you can do so via:</h2>
                        <div className="mx-auto mt-10 flex flex-col xl:flex-row items-center justify-center gap-x-10 sm:max-w-xl sm:gap-x-10 lg:mx-0 lg:max-w-none">
                            {actions.map((action) => (
                                <a href={action.link} target='blank'>
                                    <img 
                                        src={action.icon}
                                        alt={action.name}
                                        width="100"
                                        height="100"
                                    />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Contact;