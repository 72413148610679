import Header from 'components/portfolio/Header';
import { RouteLocation } from 'enums/routeLocation.enum';
import { ViewProps } from 'interfaces/ViewProps';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NoMatch: React.FC<ViewProps> = ({actualRoute, setActualRoute}) => {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col min-h-screen' >
            <Header actualRoute={actualRoute} setActualRoute={setActualRoute} />
            <main className="flex flex-col min-h-full place-items-center bg-white px-6 py-10 lg:px-8">
                <div className="text-center">
                    <p className="text-2xl font-semibold text-darker">404</p>
                    <h1 className="mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl mb-10">Wrong Way</h1>
                    <div className="tenor-gif-embed" data-postid="15644562" data-share-method="host" data-aspect-ratio="1.77778" data-width="100%"><a href="https://tenor.com/view/crazy-taxi-sega-the-wrong-way-driving-gif-15644562">Crazy Taxi GIF</a>from <a href="https://tenor.com/search/crazy-gifs">Crazy GIFs</a></div> <script type="text/javascript" async src="https://tenor.com/embed.js"></script>
                    <p className="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">Sorry, you're on the wrong path.</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <button
                            className="rounded-md bg-darker px-3.5 py-2.5 text-sm font-semibold font-montserrat text-lighter hover:text-darker shadow-sm hover:bg-regular focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                            onClick={() => navigate(`/${RouteLocation.Dashboard}`)}
                        >
                            Go to Dashboard
                        </button>
                    </div>
                </div>
            </main>
        </div>
        
    );
}

export default NoMatch;