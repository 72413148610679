import React from 'react';
import { RouteLocation } from 'enums/routeLocation.enum';
import { useNavigate } from 'react-router-dom';

interface HeaderButtonProps {
    actualRoute: RouteLocation;
    routeToCompare: RouteLocation;
    setActualRoute: (value: RouteLocation) => void;
    isMobile?: boolean;
}

const HeaderButton: React.FC<HeaderButtonProps> = ({actualRoute, routeToCompare, setActualRoute, isMobile}) => {
    const navigate = useNavigate();
    const isActive = actualRoute === routeToCompare;
    const handleOnCLick = () => {
        setActualRoute(routeToCompare);
        navigate(`/${routeToCompare}`)
    }

    return (
        <button 
            className={`rounded-md px-3 py-2 font-medium
                ${isMobile ? "block text-base" : "text-sm"}
                ${isActive ? " bg-gray-900 text-white" : "text-lighter hover:bg-regular hover:text-darker"}
            `}
            aria-current={isActive ? "page" : undefined}
            onClick={handleOnCLick}
        >
            {routeToCompare}
        </button>
    );
}

export default HeaderButton;