import React from 'react';
import { ViewProps } from 'interfaces/ViewProps';
import Header from 'components/portfolio/Header';

const Home: React.FC<ViewProps> = ({actualRoute, setActualRoute}) => {
  const calExperienceYears = () => {
    const currentYear = new Date().getFullYear();
    return currentYear - 2015;
  }
  return (
    <div>
      <Header actualRoute={actualRoute} setActualRoute={setActualRoute} />
        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-darker">Introduction</h1>
          </div>
      </header>
      <main>
          <div className="flex flex-col lg:flex-row gap-4 mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <div className="flex flex-col gap-4 lg:max-w-5xl">
              <p className="text-2xl font-montserrat text-darker">Hi, I'm Erick Subero</p>
              <p className="text-lg font-montserrat text-darker">Venezuelan <b>Frontend developer</b> and <b>Game Developer</b> with {calExperienceYears()} years of experience in developing large and small scale projects</p>
              <p className="text-lg font-montserrat text-darker">Initially focused on game development as a programmer and game designer but currently entering the field of web development as a personal challenge</p>
              <p className="text-lg font-montserrat text-darker">As a game developer I have worked mainly on the game engine, "Unity", programming in C# and simple shaders with HLSL.</p>
              <p className="text-lg font-montserrat text-darker">Like every game developer in independent development, I have intermediate knowledge in texturing (Photoshop, Gimp, Substance Designer), modeling (Maya, Blender), UI, among others.</p>
              <p className="text-lg font-montserrat text-darker">As a Frontend Developer, I have worked primarily with <b>React</b> but I have intervened in different frameworks (Laravel, Gatsby, Shopify) and tools (SquareSpace, Webflow, ActiveCampaign). </p>
              <p className="text-lg font-montserrat text-darker">My experience in both branches has led me to handle multiple tools that help project management such as Jira, Confluence, Trello, Notion, Miro or ClickUp.</p>
            </div>
            <div className='flex flex-col items-center'>
              <img src="https://media.licdn.com/dms/image/v2/C4E03AQH6VSYRp_7Q-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645143381293?e=1742428800&v=beta&t=si_t1ubrsACH8k66vht9Gr0sgCvS2C1Sz0UCce0XSHc" alt="" />
            </div>
          </div>
      </main>
    </div>
  );
}

export default Home;